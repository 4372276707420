import React from "react";
import { Helmet } from "react-helmet";
import BuyBody from "../components/buy/BuyBody";

const Buy = () => {
  return (
    <>
      <Helmet>
        <link rel="conanical" href="https://tltbook.web.app/buy" />
        <title>Buy The Lifetime Treasure Book Copy</title>
      </Helmet>
      <BuyBody />
    </>
  );
};

export default Buy;
